import React from "react"
import { Link } from "gatsby"
import "normalize.css"
import styled from "styled-components"
import SEO from "../components/SEO"

const QuoteStyles = styled.div`
    width: 100vw;
    height: 100vh;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;

    @media (max-width: 600px) {
      height: 90.3354vh;
  }
    
  .quote {
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .quote__wrap {
    width: 1300px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .quote__text {
    font-family: "calluna_bold";
    font-size: 36px;
    text-align: center;
    line-height: 1.4;
  }
  .quote__icon {
    font-size: 120px;
    height: 90px;
    font-family: calluna_regular;
    color: var(--clr-primary);
  }
  .quote__author {
    margin-top: 35px;
    text-align: center;
    font-size: 28px;
    line-height: 1.4;
  }
  a {
    font-family: "Open sans";
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 4px;
    color: var(--clr-white);
    background-color: var(--clr-black);
    margin-top: 60px;
    padding: 18px 40px;
    border: var(--border);
    border-radius: 10px;
  }
  a:hover {
    color: var(--clr-white);
    background-color:  var(--clr-primary);
    border-color: var(--clr-primary);
    transition: 0.15s;
  }

  @media (max-width: 1500px) {
    .quote__wrap {
      width: 75%;
    }
    .quote__icon {
      font-size: 110px;
      height: 76px;
    }
    .quote__text {
      font-size: 33px;
    }
    .quote__author {
      margin-top: 32px;
      font-size: 26px;
    }
    a {
      margin-top: 50px;
      font-size: 18px;
      padding: 16px 36px;
    }
  }

  @media (max-width: 1350px) {
    .quote__text br {
      display: none;
    }
  }

  @media (max-width: 1100px) {
    .quote__icon {
      font-size: 110px;
      height: 70px;
    }
    .quote__text {
      font-size: 30px;
    }
    .quote__author {
      margin-top: 26px;
      font-size: 24px;
    }
    a {
      margin-top: 44px;
      font-size: 18px;
      padding: 16px 36px;
    }
  }

  @media (max-width: 900px) {
    .quote__icon {
      font-size: 100px;
      height: 74px;
    }
    .quote__text {
      font-size: 26px;
    }
    .quote__author {
      margin-top: 24px;
      font-size: 22px;
    }
    a {
      margin-top: 38px;
      font-size: 16px;
      padding: 16px 36px;
    }
  }

  @media (max-width: 600px) {
    .quote__wrap {
      width: 85%;
    }
  }

  @media (max-width: 480px) {
    .quote__wrap {
      width: 88%;
    }
    .quote__icon {
      font-size: 80px;
      height: 60px;
    }
    .quote__text {
      font-size: 23px;
    }
    .quote__author {
      margin-top: 24px;
      font-size: 21px;
    }
    a {
      margin-top: 30px;
      font-size: 15px;
      padding: 15px 34px;
    }
  }

  @media (max-width: 400px) {
    .quote__wrap {
      width: 88%;
    }
    .quote__icon {
      font-size: 74px;
      height: 48px;
    }
    .quote__text {
      font-size: 20px;
    }
    .quote__author {
      margin-top: 16px;
      font-size: 18px;
    }
    a {
      margin-top: 24px;
      font-size: 13px;
      padding: 13px 30px;
    }
  }

  
`

function WelcomePage() {
  return (
    <>
      <SEO title="Welcome" />
      <QuoteStyles>
        <div className="quote">
          <div className="quote__wrap">
            <div className="quote__icon">&#8220;</div>
            <blockquote className="quote__text">
              World events do not occur by accident. They are made to happen, <br />whether it is to do with national issues or commerce; and most of them <br />are staged and managed by those who hold the purse
              strings.
            </blockquote>
            <h3 className="quote__author">
              - Denis Healey, Former UK Secretary of State for Defence
            </h3>
            <Link to="/home">enter site</Link>
          </div>
        </div>
      </QuoteStyles>
    </>
  )
}

export default WelcomePage
